import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

import { twMerge } from 'tailwind-merge';

const primaryColorsCSS =
  'bg-primary-200/70 hover:bg-primary-300/70 dark:bg-primary-600 shadow-sm dark:hover:bg-primary-500 focus-visible:outline-primary-600';

const ghostCSS = 'bg-black/5 hover:bg-black/10 dark:bg-white/5 dark:hover:bg-white/10 focus-visible:outline-white';

const ghostOnDarkCSS = 'text-white bg-white/10 hover:bg-white/20 focus-visible:outline-white';

const focusCSS = 'focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600';

const disabledCSS = 'disabled:opacity-50 disabled:cursor-not-allowed';

const otherCSS = twMerge(
  focusCSS,
  'inline-flex gap-x-2 rounded-md px-3.5 py-2.5 sm:min-w-[7rem]',
  'transition-all cursor-pointer select-none',
  'tracking-wide font-semibold shadow-sm text-sm justify-center items-center',
  '[&_svg]:shrink-0 [&_svg]:grow-0',
  disabledCSS,
);

export const Anchor = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return <a {...props} className={twMerge(primaryColorsCSS, otherCSS, props.className)} role="button" />;
};

export const Button = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button {...props} className={twMerge(primaryColorsCSS, otherCSS, props.className)} />;
};

export const DangerButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const dangerCss = 'bg-red-200 hover:bg-red-300 dark:bg-red-500/50 dark:hover:bg-red-500/60';
  return <button {...props} className={twMerge(primaryColorsCSS, otherCSS, dangerCss, props.className)} />;
};

export const ButtonAnchor = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return <a {...props} className={twMerge(primaryColorsCSS, otherCSS, props.className)} role="button" />;
};

export const GhostButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button {...props} className={twMerge(ghostCSS, otherCSS, props.className)} />;
};

export const GhostAnchor = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return <a {...props} className={twMerge(ghostCSS, otherCSS, props.className)} role="button" />;
};

export const GhostOnDarkButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button {...props} className={twMerge(ghostOnDarkCSS, otherCSS, props.className)} />;
};

export const GhostOnDarkAnchor = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return <a {...props} className={twMerge(ghostOnDarkCSS, otherCSS, props.className)} role="button" />;
};
